// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../styles/typography.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatTooltip--row--tFzNn {
  line-height: 16px;
  max-width: 180px;
  min-width: 160px;
}

.StatTooltip--message--MOr7T {
  color: var(--stat--default);
}

.StatTooltip--message--MOr7T p {
  margin: 0;
}

.StatTooltip--message--MOr7T a {
  pointer-events: all;
}

.StatTooltip--divider--gqRkM {
  height: 2px;
  margin: 10px -12px;
  background-color: var(--stat--default);
}

.StatTooltip--hiddenDivider--AMMfk {
  height: 10px;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/tooltips/StatTooltip.css"],"names":[],"mappings":"AAAA;EAEE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EAEE,2BAA2B;AAC7B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B","sourcesContent":[".row {\n  composes: smallSize from '../../../styles/typography.css';\n  line-height: 16px;\n  max-width: 180px;\n  min-width: 160px;\n}\n\n.message {\n  composes: row;\n  color: var(--stat--default);\n}\n\n.message p {\n  margin: 0;\n}\n\n.message a {\n  pointer-events: all;\n}\n\n.divider {\n  height: 2px;\n  margin: 10px -12px;\n  background-color: var(--stat--default);\n}\n\n.hiddenDivider {\n  height: 10px;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `StatTooltip--row--tFzNn ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"message": `StatTooltip--message--MOr7T StatTooltip--row--tFzNn ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"divider": `StatTooltip--divider--gqRkM`,
	"hiddenDivider": `StatTooltip--hiddenDivider--AMMfk`
};
export default ___CSS_LOADER_EXPORT___;
