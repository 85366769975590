// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../styles/typography.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClipboardButton--copyButton--w99lI {
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--text-medium-contrast);
  background-color: var(--white);
  border: solid 1px var(--stat--border);
  outline: none;
  position: relative;
  text-align: left;
  line-height: 1;
}

.ClipboardButton--copyButton--w99lI:hover {
  background-image: none;
  color: var(--white);
  background-color: var(--brand-purple);
  border-color: var(--brand-purple);
}

.ClipboardButton--copyButton--w99lI:active {
  background-image: none;
  color: var(--white);
  background-color: var(--brand-purple);
  border-color: var(--brand-purple);
  opacity: 0.62;
}

.ClipboardButton--buttonText--mjoj5, .ClipboardButton--successText--nNMjn {
  margin: 0;
  padding: 0;
  display: block;
}

.ClipboardButton--copyButton--w99lI.ClipboardButton--buttonTextHidden--I_vrY .ClipboardButton--buttonText--mjoj5 {
  visibility: hidden;
  height: 0;
}

.ClipboardButton--copyButton--w99lI.ClipboardButton--successTextHidden--uGDmL .ClipboardButton--successText--nNMjn {
  visibility: hidden;
  height: 0;
}

@media print {
  .ClipboardButton--copyButton--w99lI {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/controls/ClipboardButton.css"],"names":[],"mappings":"AAAA;EAEE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,kCAAkC;EAClC,8BAA8B;EAC9B,qCAAqC;EACrC,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,qCAAqC;EACrC,iCAAiC;AACnC;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,qCAAqC;EACrC,iCAAiC;EACjC,aAAa;AACf;;AAEA;EACE,SAAS;EACT,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".copyButton {\n  composes: defaultSize from '../../../styles/typography.css';\n  padding: 5px 10px;\n  border-radius: 4px;\n  cursor: pointer;\n  color: var(--text-medium-contrast);\n  background-color: var(--white);\n  border: solid 1px var(--stat--border);\n  outline: none;\n  position: relative;\n  text-align: left;\n  line-height: 1;\n}\n\n.copyButton:hover {\n  background-image: none;\n  color: var(--white);\n  background-color: var(--brand-purple);\n  border-color: var(--brand-purple);\n}\n\n.copyButton:active {\n  background-image: none;\n  color: var(--white);\n  background-color: var(--brand-purple);\n  border-color: var(--brand-purple);\n  opacity: 0.62;\n}\n\n.buttonText, .successText {\n  margin: 0;\n  padding: 0;\n  display: block;\n}\n\n.copyButton.buttonTextHidden .buttonText {\n  visibility: hidden;\n  height: 0;\n}\n\n.copyButton.successTextHidden .successText {\n  visibility: hidden;\n  height: 0;\n}\n\n@media print {\n  .copyButton {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyButton": `ClipboardButton--copyButton--w99lI ${___CSS_LOADER_ICSS_IMPORT_0___.locals["defaultSize"]}`,
	"buttonText": `ClipboardButton--buttonText--mjoj5`,
	"successText": `ClipboardButton--successText--nNMjn`,
	"buttonTextHidden": `ClipboardButton--buttonTextHidden--I_vrY`,
	"successTextHidden": `ClipboardButton--successTextHidden--uGDmL`
};
export default ___CSS_LOADER_EXPORT___;
