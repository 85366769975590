// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../styles/typography.css";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../styles/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PumpSettingsOverrideTooltip--container--EgGUi {
  opacity: 1;
  display: flex;
  flex-direction: column;
  min-width: 80px;
  margin: 2px 0;
  max-width: 250px;
}

.PumpSettingsOverrideTooltip--row--Mh8Fk {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 20px;
}

.PumpSettingsOverrideTooltip--label--Yjy9X {
  flex-grow: 1;
}

.PumpSettingsOverrideTooltip--boldLabel--DX0Wm {
  font-weight: bold;
}

.PumpSettingsOverrideTooltip--value--_s7MU {
  margin-left: 20px;
}

.PumpSettingsOverrideTooltip--title--jE99D {
  text-align: center;
}

.PumpSettingsOverrideTooltip--target--PcPtu {
}

.PumpSettingsOverrideTooltip--overrideType--NdbGs {
  font-weight: normal;
}

.PumpSettingsOverrideTooltip--overrideType--NdbGs div:last-child {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/daily/pumpsettingsoverridetooltip/PumpSettingsOverrideTooltip.css"],"names":[],"mappings":"AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,aAAa;EACb,gBAAgB;AAClB;;AAEA;EAEE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EAEE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EAEE,kBAAkB;AACpB;;AAEA;AAEA;;AAEA;EAEE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[" @import '../../../styles/colors.css';\n\n.container {\n  opacity: 1;\n  display: flex;\n  flex-direction: column;\n  min-width: 80px;\n  margin: 2px 0;\n  max-width: 250px;\n}\n\n.row {\n  composes: smallSize from '../../../styles/typography.css';\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  line-height: 20px;\n}\n\n.label {\n  flex-grow: 1;\n}\n\n.boldLabel {\n  composes: label;\n  font-weight: bold;\n}\n\n.value {\n  margin-left: 20px;\n}\n\n.title {\n  composes: smallSize from '../../../styles/typography.css';\n  text-align: center;\n}\n\n.target {\n  composes: row;\n}\n\n.overrideType {\n  composes: row;\n  font-weight: normal;\n}\n\n.overrideType div:last-child {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PumpSettingsOverrideTooltip--container--EgGUi`,
	"row": `PumpSettingsOverrideTooltip--row--Mh8Fk ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"label": `PumpSettingsOverrideTooltip--label--Yjy9X`,
	"boldLabel": `PumpSettingsOverrideTooltip--boldLabel--DX0Wm PumpSettingsOverrideTooltip--label--Yjy9X`,
	"value": `PumpSettingsOverrideTooltip--value--_s7MU`,
	"title": `PumpSettingsOverrideTooltip--title--jE99D ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"target": `PumpSettingsOverrideTooltip--target--PcPtu PumpSettingsOverrideTooltip--row--Mh8Fk ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"overrideType": `PumpSettingsOverrideTooltip--overrideType--NdbGs PumpSettingsOverrideTooltip--row--Mh8Fk ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`
};
export default ___CSS_LOADER_EXPORT___;
